import { PendingUser, UserInfo } from './types/controllers/AuthControllerTypes';
import { UpsertCartPartRequest } from './types/controllers/WidgetCartControllerTypes';
import {
  WidgetBatchQtyAndPriceResponse,
  WidgetBatchQtyAndPricePayload,
} from './types/controllers/WidgetPartControllerTypes';
import { WidgetQuoteCreatePayload } from './types/controllers/WidgetQuoteControllerTypes';

/**
 * The names of all the message coming from widget to the loader and the host's website
 */
export const MessagesFromWidget = {
  CHANGE_IFRAME_SIZE: 'SP_CHANGE_IFRAME_SIZE',
  IFRAME_LOADED: 'SP_IFRAME_LOADED',
  USER_LOGGED_IN: 'SP_USER_LOGGED_IN',
  USER_SIGNED_UP: 'SP_USER_SIGNED_UP',
  USER_LOGGED_OUT: 'SP_USER_LOGGED_OUT',
  PENDING_USER_CREATED: 'SP_PENDING_USER_CREATED',
  INITIALIZED: 'SP_INITIALIZED',
  NAVIGATE_IN_HOST: 'SP_NAVIGATE_IN_HOST',
  MULTIPLE_PARTS_PRICE_RESPONSE: 'SP_MULTIPLE_PARTS_PRICE_RESPONSE',
  SET_AUTH_USER: 'SP_SET_AUTH_USER',
  SET_PENDING_USER: 'SP_SET_PENDING_USER',
} as const;

/**
 * The names of all the message expected widget to receive the loader and the host's website
 */
export const MessagesToWidget = {
  OPEN_CHAT_PAGE: 'SP_OPEN_CHAT_PAGE',
  OPEN_SEARCH_PAGE: 'SP_OPEN_SEARCH_PAGE',
  OPEN_LOGIN_PAGE: 'SP_OPEN_LOGIN_PAGE',
  OPEN_SIGNUP_PAGE: 'SP_OPEN_SIGNUP_PAGE',
  COLLAPSE: 'SP_COLLAPSE',
  SHOW: 'SP_SHOW',
  HIDE: 'SP_HIDE',
  RELOAD: 'SP_RELOAD',
  ADD_TO_CART: 'SP_ADD_TO_CART',
  BUY_NOW: 'SP_BUY_NOW',
  REQUEST_MULTIPLE_PARTS_PRICES: 'SP_REQUEST_MULTIPLE_PARTS_PRICE',
  CREATE_QUOTE: 'SP_CREATE_QUOTE',
  NOTIFY_HOST_WAS_RESIZED: 'SP_NOTIFY_HOST_WAS_RESIZED',
  NOTIFY_USER_HAS_LOGGED_IN: 'SP_NOTIFY_USER_HAS_LOGGED_IN',
  NOTIFY_USER_HAS_SIGNED_UP: 'SP_NOTIFY_USER_HAS_SIGNED_UP',
  NOTIFY_USER_HAS_LOGGED_OUT: 'SP_NOTIFY_USER_HAS_LOGGED_OUT',
  NOTIFY_PENDING_USER_WAS_CREATED: 'SP_NOTIFY_PENDING_USER_WAS_CREATED',
  NOTIFY_SCROLL_POSITION: 'SP_NOTIFY_SCROLL_POSITION',
} as const;

export type AllMessagesToWidget =
  (typeof MessagesToWidget)[keyof typeof MessagesToWidget];
export type AllMessagesFromWidget =
  (typeof MessagesFromWidget)[keyof typeof MessagesFromWidget];

type MessageToWidgetData =
  | {
      action: typeof MessagesToWidget.ADD_TO_CART;
      payload: UpsertCartPartRequest;
    }
  | { action: typeof MessagesToWidget.NOTIFY_HOST_WAS_RESIZED; payload: number }
  | { action: typeof MessagesToWidget.NOTIFY_SCROLL_POSITION; payload: number }
  | { action: typeof MessagesToWidget.BUY_NOW; payload: UpsertCartPartRequest }
  | {
      action: typeof MessagesToWidget.CREATE_QUOTE;
      payload: WidgetQuoteCreatePayload;
    }
  | {
      action: typeof MessagesToWidget.REQUEST_MULTIPLE_PARTS_PRICES;
      payload: WidgetBatchQtyAndPricePayload;
    }
  | { action: typeof MessagesToWidget.OPEN_CHAT_PAGE }
  | { action: typeof MessagesToWidget.OPEN_SEARCH_PAGE }
  | { action: typeof MessagesToWidget.OPEN_LOGIN_PAGE }
  | { action: typeof MessagesToWidget.OPEN_SIGNUP_PAGE }
  | { action: typeof MessagesToWidget.COLLAPSE }
  | { action: typeof MessagesToWidget.SHOW }
  | { action: typeof MessagesToWidget.HIDE }
  | { action: typeof MessagesToWidget.RELOAD }
  | {
      action: typeof MessagesToWidget.NOTIFY_USER_HAS_LOGGED_IN;
      payload: undefined;
    }
  | {
      action: typeof MessagesToWidget.NOTIFY_USER_HAS_SIGNED_UP;
      payload: undefined;
    }
  | {
      action: typeof MessagesToWidget.NOTIFY_USER_HAS_LOGGED_OUT;
      payload: undefined;
    }
  | { action: typeof MessagesToWidget.NOTIFY_PENDING_USER_WAS_CREATED };

export type MultiplePartsPriceResponseEvent = {
  action: typeof MessagesFromWidget.MULTIPLE_PARTS_PRICE_RESPONSE;
  payload: {
    pricesAndOriginalPrices: {
      partID: WidgetBatchQtyAndPriceResponse['parts'][0]['id'];
      price: WidgetBatchQtyAndPriceResponse['parts'][0]['price'];
      originalPrice: WidgetBatchQtyAndPriceResponse['parts'][0]['originalPrice'];
    }[];
    authUser?: UserInfo | null;
  };
};

export type MessageFromWidgetData =
  | MultiplePartsPriceResponseEvent
  | {
      action: typeof MessagesFromWidget.CHANGE_IFRAME_SIZE;
      payload: { width: string; height: string };
    }
  | { action: typeof MessagesFromWidget.IFRAME_LOADED }
  | { action: typeof MessagesFromWidget.USER_LOGGED_IN }
  | { action: typeof MessagesFromWidget.USER_SIGNED_UP }
  | { action: typeof MessagesFromWidget.USER_LOGGED_OUT }
  | {
      action: typeof MessagesFromWidget.PENDING_USER_CREATED;
    }
  | {
      action: typeof MessagesFromWidget.INITIALIZED;
      payload: {
        authUser: UserInfo | undefined;
        pendingUser: PendingUser | undefined;
      };
    }
  | {
      action: typeof MessagesFromWidget.NAVIGATE_IN_HOST;
      payload: string;
    }
  | {
      action: typeof MessagesFromWidget.SET_AUTH_USER;
      payload: UserInfo | undefined;
    }
  | {
      action: typeof MessagesFromWidget.SET_PENDING_USER;
      payload: PendingUser | undefined;
    };

export type MessageToWidgetEvent = MessageEvent<MessageToWidgetData>;
export type MessageFromWidgetEvent = MessageEvent<MessageFromWidgetData>;
