import { MessagesFromWidget } from '@/shared/SalesPilotConstants';
import { notifyHostWasResized, notifyScrollPosition } from './postMessages';
import { getEndpointUrl, throttle } from './utils';

const IFRAME_ID = process.env.IFRAME_ID;
const TENANT_ALIAS_DATA_ATTRIBUTE = 'data-salespilot-tenant-alias';

const createSaaSIframe = () => {
  const currentScript = document.querySelector(
    `script[${TENANT_ALIAS_DATA_ATTRIBUTE}]`,
  );

  const tenantAlias = currentScript?.getAttribute(TENANT_ALIAS_DATA_ATTRIBUTE);
  if (!tenantAlias) {
    throw new Error('Provided SalesPilot tenantAlias is undefined.');
  }

  const endpointUrl = getEndpointUrl();
  if (!endpointUrl) {
    throw new Error('Provided SalesPilot endpointUrl is undefined.');
  }

  if (window.navigator.userAgent.toLowerCase().includes('bot')) {
    console.warn('The iframe should not load when browsed by a bot.');
    return;
  }

  const widgetUrl = new URLSearchParams(window.location.search).get('_spUrl');

  const routerInitialValue = widgetUrl ? `#${widgetUrl}` : '';

  const url = `${endpointUrl}/index.html?tenantAlias=${tenantAlias}${routerInitialValue}`;
  const iframe = document.createElement('iframe');
  iframe.id = IFRAME_ID;
  iframe.title = 'Sales Pilot Iframe';
  iframe.name = IFRAME_ID;
  iframe.src = url;
  iframe.allow = 'clipboard-write';
  iframe.style.position = 'fixed';
  iframe.style.bottom = '0';
  iframe.style.right = '0';
  iframe.style.width = '0';
  iframe.style.height = '0';
  iframe.style.maxHeight = '100%';
  iframe.style.border = '0 none';
  iframe.style.zIndex = '99999';

  document.body.appendChild(iframe);

  const intervalID = setInterval(() => {
    // On hydration errors in next.js the iframe is not appended to the body (still not exactly sure why)
    // This code attempts to append the iframe to the body if it's not there
    // @see https://www.notion.so/quotebeam/Hydration-error-on-SPS-PDP-causes-Widget-to-not-load-162e3340a1b8804d9a11fa96b0d17b61
    if (!document.getElementById(IFRAME_ID)) {
      document.body.appendChild(iframe);
    } else {
      clearInterval(intervalID);
    }
  }, 1000);
};

const receiveResizeMessage = (payload) => {
  if (payload?.width && payload?.height) {
    const iframe = document.getElementById(IFRAME_ID);
    if (iframe) {
      Object.keys(payload).forEach((key) => {
        iframe.style[key] = payload[key];
      });
    }
  }
};

const listenToMessagesFromTheSaaS = () => {
  window.addEventListener(
    'message',
    ({ data }) => {
      if (data?.action === MessagesFromWidget.CHANGE_IFRAME_SIZE) {
        receiveResizeMessage(data?.payload);
      }
      /** Used to send the initial parent width after iframe loads */
      if (data?.action === MessagesFromWidget.IFRAME_LOADED) {
        notifyHostWasResized();
      }
      if (data?.action === MessagesFromWidget.NAVIGATE_IN_HOST) {
        window.location.assign(data?.payload);
      }
    },
    false,
  );
};

const throttledSendWidthMessageToIframe = throttle(notifyHostWasResized, 150);
const throttledSendScrollPositionMessageToIframe = throttle(
  notifyScrollPosition,
  250,
);

const sendMessagesToTheSaaS = () => {
  window.addEventListener('resize', throttledSendWidthMessageToIframe);
  window.addEventListener('scroll', throttledSendScrollPositionMessageToIframe);
};

(function () {
  createSaaSIframe();
  listenToMessagesFromTheSaaS();
  sendMessagesToTheSaaS();
})();
